.wrapper
  display: flex
  flex-direction: row
  justify-content: flex-end
  & .button
    display: flex
    padding: 0px 10px
    align-items: center
    & svg
      margin-right: 5px
      margin-bottom: -6px
