
.wrapper
  display: flex
  align-items: center
  justify-content: flex-start
  color: #fff
  width: 100%
  & > a
    all: unset
    cursor: pointer
    margin: 0 30px
  & > h1
    font-size: 22px
