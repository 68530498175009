
.wrapper
  display: flex
  flex-direction: row
  justify-content: stretch
  flex-wrap: wrap
  margin-bottom: 30px
  & > .left
    display: flex
    flex-basis: 60%
    flex-direction: column
    row-gap: 20px
    justify-content: flex-end
    & .searchBar
      & :global(.ant-select-clear)
        right: 55px
  & > .right
    display: flex
    flex-direction: column
    flex-basis: 40%
    & > .title
      display: flex
      align-items: center
      column-gap: 8px
    & > ul.filters
      all: unset
      display: flex
      flex-direction: row
      justify-content: flex-start
      column-gap: 30px
      & > li
        all: unset
        display: flex
        flex-direction: column
        & > label
          margin-bottom: 5px