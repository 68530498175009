.wrapper
  & .statistics
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    gap: 20px
    align-items: center
    flex-wrap: wrap
    & > div
      margin: 10px 0
      & > h2
        text-align: center
        font-size: 16px
        margin: 5px auto
