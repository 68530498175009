@mixin filter()
  display: flex
  flex-direction: column
  flex-shrink: 0
  & > label
    margin-bottom: 5px

.wrapper
  display: flex
  flex-direction: column
  justify-content: space-between
  margin-bottom: 30px
  flex-wrap: wrap
  & > .row
    display: flex
    flex-direction: row
    margin-bottom: 20px
    align-items: flex-end
    flex-wrap: wrap
  & .left
    display: flex
    flex-direction: row
    align-items: flex-end
    flex-wrap: wrap
    margin-right: 30px
    padding-right: 30px
    border-right: 1px solid #e5e5e5
    & > .searchBar
      margin-right: 30px
      width: 450px
  & .filter
    @include filter()
  & .right
    display: flex
    flex-grow: 1
    align-items: flex-end
    justify-content: flex-start
    & > div > .actions
      margin-right: 20px
      & svg
        margin-bottom: -4px
        height: 17px
        margin-right: 5px
